import React, {useState} from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import AddCircle from '@material-ui/icons/AddCircle';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import { IconButton } from "@material-ui/core"
import { useJobNumContext } from "../context/JobNumContext"
import DeleteIcon from '@material-ui/icons/Delete';
import DropDown from './DropDown'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))


const types = [
    "Commission",
    "Media Buy",
    "Media Management Fee",
    "Retainer",
    "Project",
    "Pass Through"
]

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]
const containerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '450px'
}

const getYears = () => {
    return ([
        new Date().getFullYear() - 1,
        new Date().getFullYear(),
        new Date().getFullYear() + 1
    ])
}
// A year selector used in the generation of a job number.
const RevenueByMonth = () => {
  const classes = useStyles()
  const {setJobNumContext, jobNumContext} = useJobNumContext()
  const [revenueError, setRevenueError] = useState(false)
  const [count, setCount] = useState('0')
  const [isEditMode, setIsEditMode] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(-1);
  const [revenueByMonth, setRevenueByMoth] = useState({year: getYears()[1], month: months[0], revenue: 0, type: "Retainer", memo: '',monthNum: 1})

  const handleYearChange = (target) => {
    setRevenueByMoth({...revenueByMonth, year: target.value})
  }

  const handleMonthChange = (target) => {
    setRevenueByMoth({...revenueByMonth, month: target.value, monthNum: months.indexOf(target.value) + 1})
  }
  const handleTypeChange = (target) => {
    setRevenueByMoth({...revenueByMonth, type: target.value})
  }

  const handleRevenueChange=({target}) => {
    if (target.value.match(/^(?!(0))[0-9]*$/)) {
        setRevenueError(false)
        setRevenueByMoth({...revenueByMonth, revenue: parseInt(target.value)})
      } else {
        setRevenueError(true)
      }
  }

  const onAddRevenueByMonth = () => {
    if(revenueByMonth.revenue > 0 && count === '0') {
        setJobNumContext({
            ...jobNumContext,
            revenueByMonth: [...jobNumContext.revenueByMonth, revenueByMonth]
        })
        setRevenueByMoth({year: getYears()[1] ,type: "Retainer", month: months[0], revenue: 0, memo: '', monthNum: 1})
    } else if (revenueByMonth.revenue > 0 && count !== '0') {
      const selectedMonthIndex = months.indexOf(revenueByMonth.month)
      if( months.length - (selectedMonthIndex + parseInt(count)) >= 0 ) {
        let array= [];
        for(let i = 0; i < count; i++) (
          array.push({...revenueByMonth, month: months[i+selectedMonthIndex], monthNum: i+selectedMonthIndex+1})
        )
        setJobNumContext({
          ...jobNumContext,
          revenueByMonth: [...jobNumContext.revenueByMonth, ...array]
      })
        setRevenueByMoth({year: getYears()[1] ,type: "Retainer", month: months[0], revenue: 0, memo: '', monthNum: 1})
        setCount(0)
        array =[]
      } else {
        let array= [];
        const numOfMonthsThisYear = months.length - selectedMonthIndex
        const numOfMonthsNextYear = count-numOfMonthsThisYear
        for(let i = 0; i < numOfMonthsThisYear; i++) {
          array.push({...revenueByMonth, month: months[i+selectedMonthIndex], monthNum: i+selectedMonthIndex+1})
        }
        for(let i = 0; i < numOfMonthsNextYear; i++) {
          array.push({...revenueByMonth, month: months[ i ], year: revenueByMonth.year + 1, monthNum: i+1})
        }
        setJobNumContext({
          ...jobNumContext,
          revenueByMonth: [...jobNumContext.revenueByMonth, ...array]
      })
        setRevenueByMoth({year: getYears()[1] ,type: "Retainer", month: months[0], revenue: 0, memo: '', monthNum: 1})
        setCount(0)
        array =[];
      }
    }
  }

  const handleMemoChange = ({target}) => {
    setRevenueByMoth({...revenueByMonth, memo: target.value})
  }

  const handleCountChange = ({target}) => {
    if (target.value.match(/^([1-9]|1[012])$/)) {
      setCount(target.value)
    }
  }

  const onEditRevenueByMonth = (i) => {
    setIsEditMode(true);
    setRevenueByMoth(jobNumContext.revenueByMonth[i]);
    setUpdateIndex(i)
  }

  const onUpdateRevenueByMonth = () => {
    jobNumContext.revenueByMonth[updateIndex] = revenueByMonth
    setIsEditMode(false)
    setUpdateIndex(-1);
    setRevenueByMoth({year: getYears()[1] ,type: "Retainer", month: months[0], revenue: 0, memo: ''})

  }

  const onDeleteRevenueByType = (i) => {
    const newRevs = [...jobNumContext.revenueByMonth];
    newRevs.splice(i,1);
    setJobNumContext({
        ...jobNumContext,
        revenueByMonth: newRevs
    })
  }
  return (
      <>
            <ul>
        {
                jobNumContext.revenueByMonth.length > 0 && jobNumContext.revenueByMonth.map((i, index ) => {
                    return(
                        <li key={index}>
                            <span style={{minWidth: '275px', display: 'inline-block'}}><em>Month/Year:</em><strong> {i.month}/{i.year}</strong></span>
                            <span style={{minWidth: '275px', display: 'inline-block'}}><em>Revenue Type:</em><strong> {i.type}</strong></span>
                            <span style={{minWidth: '150px', display: 'inline-block'}}><em>Revenue:</em><strong> ${i.revenue}</strong></span>
                            <span style={{minWidth: '150px', display: 'inline-block'}}><em>Memo:</em><strong> {i.memo}</strong></span>
                            <span>
                              <IconButton onClick={onEditRevenueByMonth.bind(this, index)}>
                                <Edit color="primary" style={{cursor: 'pointer'}} />
                              </IconButton>
                            </span>
                            <span>
                              <IconButton onClick={onDeleteRevenueByType.bind(this, index)}>
                                  <DeleteIcon color="error" style={{cursor: 'pointer'}} />
                              </IconButton>
                            </span>
                        </li>
                    )
                })
            }
            </ul>
        <div style={containerStyles}>
            <DropDown options={getYears()} label="Year" onChange={handleYearChange} value={revenueByMonth.year}/>
            <DropDown options={months} label="Month" onChange={handleMonthChange} value={revenueByMonth.month}/>
            <DropDown options={types} label="Revenue Type" onChange={handleTypeChange} value={revenueByMonth.type}/>
            <FormControl className={classes.formControl}>
                <TextField
                id="revenue"
                label="Revenue"
                value={revenueByMonth.revenue}
                onChange={handleRevenueChange}
                error={revenueError}
                type="number"
                helperText="Only enter numeric values"
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField
                id="memo"
                label="Memo"
                value={revenueByMonth.memo}
                onChange={handleMemoChange}
                // error={revenueError}
                // helperText="Only enter numeric values"
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField
                id="count"
                label="Replicate Count"
                value={count}
                onChange={handleCountChange}
                type="number"
                // error={revenueError}
                helperText="Enter a number between 1-12"
                />
            </FormControl>
            {
              !isEditMode ? 
              <IconButton onClick={onAddRevenueByMonth} disabled={revenueByMonth.revenue === 0}>
                <AddCircle color="primary" style={{cursor: 'pointer'}} />
              </IconButton>:
              <IconButton onClick={onUpdateRevenueByMonth} disabled={revenueByMonth.revenue === 0}>
                <Check color="primary" style={{cursor: 'pointer'}} />
              </IconButton>
            }
        </div>
      </>
  )
}

export default RevenueByMonth
