/**
 * 
 * @param {array=} jobs - An array of job Objects
 * @param {string=} marketFilter - The current client name to filter by (can be null)
 * @returns Jobs Array filtered by client if clientName is supplied, otherwise returns the initial jobs array
 */

const filterByClient = (jobs, marketFilter) => 
  marketFilter 
    ? jobs.filter(job => job.market === marketFilter) 
    : jobs

export default filterByClient
