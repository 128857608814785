import React, { useState } from "react"
import { useHistory } from "react-router"
import { firestore } from "../firebase"
import { useCollection } from "react-firebase-hooks/firestore"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import { FormControl, Typography } from "@material-ui/core"
import { DataGrid } from "@mui/x-data-grid"
import { DropzoneDialog } from "material-ui-dropzone"
import DownloadCsv from "../components/DownloadCsv"
import {
  filterByClient,
  filterByMarket,
  filterByDate,
  filterBySOW,
  convertToObjects
} from "../utils"
import firebase from "firebase"
import GridCellExpand from "../components/GridCellExpand"
import { timeConverter } from "../utils/dateConverter"

const useStyles = makeStyles(theme => ({
  jobResults: {
    flexGrow: 1,
    margin: 20
  },
  card: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    marginTop: 18
  }
}))

// A much more complicated datagrid view of all of the current jobs.
// you can filter down the paginated results and edit cells to update the data.
const CurrentSOWs = () => {
  const history = useHistory()
  const classes = useStyles()
  const [clientFilter, setClientFilter] = useState("")
  const [marketFilter, setMarketFilter] = useState("")
  const [dateRangeLow, setDateRangeLow] = useState("")
  const [dateRangeHigh, setDateRangeHigh] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState(undefined)
  const [sowSearchString, setSOWSearchString] = useState("")
  const [allJobsValue, AllJobsLoading, AllJobsError] = useCollection(
    firestore.collection("allSOWs"),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )
  const [allClientsValue] = useCollection(firestore.collection("clients"), {
    snapshotListenOptions: { includeMetadataChanges: true }
  })

  const handleClientFilter = ({ target }) => {
    setMarketFilter("")
    setSOWSearchString("")
    setClientFilter(target.value)
  }

  const handleMarketFilter = ({ target }) => {
    setMarketFilter(target.value)
  }

  const handleDateRangeLow = ({ target }) => {
    setDateRangeLow(target.value)
  }

  const handleDateRangeHigh = ({ target }) => {
    setDateRangeHigh(target.value)
  }

  const handleSOWSearch = ({ target }) => {
    setSOWSearchString(target.value)
  }

  const handleClientClear = () => {
    setSOWSearchString("")
    setMarketFilter("")
    setClientFilter("")
    setDateRangeLow("")
    setDateRangeHigh("")
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300
    },
    {
      field: "description",
      headerName: "Description",
      width: 150
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 150,
      renderCell: params => timeConverter(params.value)
    },
    {
      field: "jobs",
      headerName: "Jobs",
      width: 350,
      renderCell: params => {
        const val = params?.value ? params.value.join(", ") : ""
        return (
          <GridCellExpand value={val} width={params.colDef.computedWidth} />
        )
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 400,
      renderCell: params => {
        const onCreateJobs = async e => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter(c => c.field !== "__check__" && !!c)
            .forEach(
              c => (thisRow[c.field] = params.getValue(params.id, c.field))
            )
          const clientName = thisRow.name.split("-")[0]

          const selectedSOW = await (
            await firebase
              .firestore()
              .doc(`clients/${clientName}/sows/${thisRow.name}`)
              .get()
          ).data()

          return history.push({ pathname: "/new-job", sow: selectedSOW })
        }

        const onEditSOW = async e => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter(c => c.field !== "__check__" && !!c)
            .forEach(
              c => (thisRow[c.field] = params.getValue(params.id, c.field))
            )
          const clientName = thisRow.name.split("-")[0]

          const selectedSOW = await (
            await firebase
              .firestore()
              .doc(`clients/${clientName}/sows/${thisRow.name}`)
              .get()
          ).data()

          return history.push({ pathname: "/edit-sow", selectedSOW })
        }
        const handleClick = e => {
          e.stopPropagation()
          setModalOpen(true)
          setModalData(params.row)
        }
        const handleUpload = async file => {
          if (!firebase) return

          const uploadedFile = file[0]
          if (!uploadedFile) return

          const storage = firebase.storage()
          const storageRef = storage.ref("files")
          if (modalData?.file) {
            storage.ref(modalData.file.filePath).delete()
            firebase.firestore().doc(`allSOWs/${modalData.name}`).update({
              file: firebase.firestore.FieldValue.delete()
            })
            firebase
              .firestore()
              .doc(`clients/${modalData.client}/sows/${modalData.name}`)
              .update({
                file: firebase.firestore.FieldValue.delete()
              })
          }

          try {
            const filePath = Date.now() + "_" + uploadedFile.name
            const uploadTask = storageRef.child(filePath).put(uploadedFile, {
              clientName: modalData.client,
              sow: modalData.name
            })

            uploadTask.on(
              "state_changed",
              snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log("Upload is " + progress + "% done")
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    break
                }
              },
              error => {
                // Handle unsuccessful uploads
                setModalOpen(false)
              },
              () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                  alert("Successfully uploaded file!")
                  firebase
                    .firestore()
                    .doc(`clients/${modalData.client}/sows/${modalData.name}`)
                    .set(
                      {
                        file: {
                          downloadURL,
                          filePath
                        }
                      },
                      {
                        merge: true
                      }
                    )
                  firebase
                    .firestore()
                    .doc(`allSOWs/${modalData.name}`)
                    .set(
                      {
                        file: {
                          downloadURL,
                          filePath: `files/${filePath}`
                        }
                      },
                      {
                        merge: true
                      }
                    )
                })
                setModalOpen(false)
              }
            )
          } catch (error) {
            console.log("error", error)
          }
        }
        return (
          <>
            <Button
              onClick={onCreateJobs}
              disabled={!params.row?.file}
              color="primary"
            >
              Create Job
            </Button>
            <Button
              style={{ marginLeft: "5px" }}
              onClick={handleClick}
              color="secondary"
            >
              Upload Files
            </Button>
            <DropzoneDialog
              filesLimit={1}
              open={modalOpen}
              onSave={handleUpload}
              acceptedFiles={["application/pdf", ".zip"]}
              showPreviews
              maxFileSize={10000000} // Set the max file size limit here (in bytes, 3MB in this example)
              onClose={() => setModalOpen(false)}
            />
            <Button
              onClick={onEditSOW}
              disabled={!!params.row?.jobs?.length}
              color="primary"
            >
              Edit SOW
            </Button>{" "}
          </>
        )
      }
    },
    {
      field: "attachment",
      headerName: "Attachment",
      sortable: false,
      width: 150,
      renderCell: params => {
        return (
          <>
            {params.row?.file ? (
              <a
                href={params.row.file.downloadURL}
                rel="noreferrer"
                target="_blank"
                download={params.row.file.filePath.split("_")[1]}
              >
                Download File
              </a>
            ) : (
              <Typography>--</Typography>
            )}
          </>
        )
      }
    }
  ]

  let results
  if (allJobsValue) {
    const objects = convertToObjects(allJobsValue.docs)
    const clientFiltered = filterByClient(objects, clientFilter)
    const sowFiltered = filterBySOW(clientFiltered, sowSearchString)
    const marketsFiltered = filterByMarket(sowFiltered, marketFilter)
    results = filterByDate(marketsFiltered, dateRangeLow, dateRangeHigh).map(
      (r, index) => ({ ...r, id: index })
    )
  }
  const [pageSize, setPageSize] = useState(10)

  return (
    <>
      {AllJobsError && (
        <strong>AllJobsError: {JSON.stringify(AllJobsError)}</strong>
      )}
      {AllJobsLoading && <span>Collection: AllJobsLoading...</span>}
      {allJobsValue && (
        <div className={classes.jobResults}>
          <Grid container spacing={3}>
            {allClientsValue && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="client-filter-dropdown">
                        Client
                      </InputLabel>
                      <Select
                        labelId="client-filter-dropdown"
                        value={clientFilter}
                        onChange={handleClientFilter}
                      >
                        {allClientsValue.docs.map((doc, index) => (
                          <MenuItem
                            key={"client" + index}
                            value={doc.data().acronym}
                          >
                            {doc.data().fullName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!!allClientsValue.docs
                      .filter(doc => doc.data().acronym === clientFilter)
                      .flatMap(doc => doc.data().markets).length && (
                      <FormControl className={classes.formControl}>
                        <InputLabel id="market-filter-dropdown">
                          Market
                        </InputLabel>
                        <Select
                          labelId="client-filter-dropdown"
                          value={marketFilter}
                          onChange={handleMarketFilter}
                        >
                          {allClientsValue.docs
                            .filter(doc => doc.data().acronym === clientFilter)
                            .flatMap(doc =>
                              doc.data().markets.map(market => (
                                <MenuItem
                                  key={market.acronym}
                                  value={market.acronym}
                                >
                                  {market.name}
                                </MenuItem>
                              ))
                            )}
                        </Select>
                      </FormControl>
                    )}
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="sow"
                        label="SOW"
                        type="text"
                        value={sowSearchString}
                        className={classes.textField}
                        onChange={handleSOWSearch}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="date"
                        label="Date Low"
                        type="date"
                        // defaultValue=""
                        value={dateRangeLow}
                        className={classes.textField}
                        onChange={handleDateRangeLow}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="date"
                        label="Date High"
                        type="date"
                        value={dateRangeHigh}
                        className={classes.textField}
                        onChange={handleDateRangeHigh}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormControl>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={handleClientClear}
                    >
                      Clear
                    </Button>
                    <DownloadCsv results={results} type="sow" />
                  </CardContent>
                </Card>
              </Grid>
            )}
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                rows={results.sort((a, b) => b.dateCreated - a.dateCreated)}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={size => setPageSize(size)}
                rowsPerPageOptions={[5, 10, 20]}
              />
            </div>
          </Grid>
        </div>
      )}
    </>
  )
}

export default CurrentSOWs
