/**
 * 
 * @param {array=} jobs - An array of job Objects
 * @param {string=} searchValue - The current client name to filter by (can be null)
 * @returns Jobs Array filtered by client if clientName is supplied, otherwise returns the initial jobs array
 */

 const filterBySOW = (jobs, searchValue) =>  searchValue 
      ? jobs.filter(job => job.name.toLowerCase().includes(searchValue.toLowerCase())) 
      : jobs

export default filterBySOW
