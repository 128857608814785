/**
 * 
 * @param {array=} jobs - Array of Jobs to filter by date range
 * @param {string=} dateLow - Low bound of date range
 * @param {string=} dateHigh - High bound of date range
 * @returns Filtered array of jobs within date bounds
 */
const filterByDate = (jobs, dateLow, dateHigh) => {
  let output = []

  if (dateLow && dateHigh) {
    output.push(...jobs.filter(({dateCreated}) => {
      return Date.parse(dateLow) < dateCreated && Date.parse(dateHigh) > dateCreated
    }))
  } else if (dateLow) {
    output.push(...jobs.filter(({dateCreated}) => {
      return Date.parse(dateLow) < dateCreated
    }))
  } else if (dateHigh) {
    output.push(...jobs.filter(({dateCreated}) => {
      return Date.parse(dateHigh) > dateCreated
    }))
  }

  return (dateLow || dateHigh)
    ? output
    : jobs
}

export default filterByDate