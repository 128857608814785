import React, { useState, useEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom"
import Updates from "./pages/Updates"
import ButtonAppBar from "./components/ButtonAppBar"
import CurrentSOWs from "./pages/CurrentSOWs"
import CurrentClients from "./pages/CurrentClients"
import Home from "./pages/Home"
import NewClients from "./pages/NewClients"
import { auth } from "./firebase"
import { Typography } from "@material-ui/core"
import CurrentJobs from "./pages/CurrentJobs"
import JobDefiner from "./components/JobDefiner"
import SOWDefiner from "./components/SOWDefiner"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider, createTheme } from "@material-ui/core"

const App = () => {
  const [authUser, setAuthUser] = useState(null)

  useEffect(() => {
    auth.onAuthStateChanged(authUser => {
      if (authUser) setAuthUser(authUser)
    })
  }, [authUser])
  const theme = createTheme({
    palette: {
      primary: {
        light: "#fff",
        main: "#000",
        dark: "#000",
        contrastText: "#fff"
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000"
      }
    }
  })

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router>
            <ButtonAppBar authUser={authUser} setAuthUser={setAuthUser} />
            <Switch>
              <Route exact path="/">
                {authUser && <Home />}
              </Route>
              <Route exact path="/new-sow">
                {authUser && <SOWDefiner isEditActive={false} />}
              </Route>
              <Route exact path="/edit-sow">
                {authUser && <SOWDefiner isEditActive={true} />}
              </Route>
              <Route exact path="/edit-job">
                {authUser && <JobDefiner isEditActive={true} />}
              </Route>
              <Route exact path="/new-job">
                {authUser && <JobDefiner isEditActive={false} />}
              </Route>
              <Route path="/current-sows">{authUser && <CurrentSOWs />}</Route>
              <Route path="/current-jobs">{authUser && <CurrentJobs />}</Route>
              <Route path="/current-clients">
                {authUser && <CurrentClients />}
              </Route>
              <Route path="/new-clients">{authUser && <NewClients />}</Route>
              <Route path="/edit-client">
                {authUser && <NewClients isEditActive={true} />}
              </Route>
              <Route path="/Updates">
                {authUser && <Updates />}
              </Route>
              <Route path="/job-addendum">
                <>
                  <Typography>
                    This page is a placeholder for adding estimates to New jobs.
                  </Typography>
                </>
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  )
}

const NoMatch = () => {
  let location = useLocation()

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
}

export default App
