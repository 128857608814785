import React, { useEffect, useState } from "react"
import Typography from "@material-ui/core/Typography"
import ClientSelect from "./ClientSelect"
import MarketSelect from "./MarketSelect"
import FYSelect from "./FYSelect"
import TextInput from "./TextInput"
import JobDescription from "./JobDescription"
import DropDown from "./DropDown"
import { pad } from "../utils"
import { firestore } from "../firebase"
import { initialValues, useJobNumContext } from "../context/JobNumContext"
import { useCollection } from "react-firebase-hooks/firestore"
import ActionNavigator from "./ActionNavigator"
import DateRangePicker from "./DatePicker"
import RevenueByMonth from "./RevenueByMonth"
import Departments from "./Departments"
import { accountServiceLeaders, status, confidence } from "../lib/data"
import { useHistory, useLocation } from "react-router-dom"
import NewClient from "./NewClient"

const getSteps = () => {
  return [
    "Select Client",
    "Select Market",
    "Is this a new client",
    // "Client Contact Name",
    // "Client Contact Email",
    "Account Service Leader",
    "Select Fiscal Year",
    "SOW Date Estimates",
    "Revenue Estimates By Month",
    "Enter SOW Description",
    "Departments Involved",
    "Confidence In Closing",
    "SOW Status",
    "Notes & Comments",
    "Finish"
  ]
}

const SOWDefiner = ({isEditActive}) => {
  const { setJobNumContext, jobNumContext } = useJobNumContext()
  const [sowNum, setSowNum] = useState("")
  const router = useHistory();
  const [fetchedClients] = useCollection(firestore.collection("clients"), {
    snapshotListenOptions: { includeMetadataChanges: true }
  })


  const data = useLocation()

  useEffect(() => {
    fetchedClients &&
      setJobNumContext({
        ...jobNumContext,
        clients: fetchedClients.docs.map(d => ({ ...d.data(), id: d.id }))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedClients])


  useEffect(() => {
   if(!data.selectedSOW && isEditActive) {
    return router.push('/new-sow')
   }else if (!data.selectedSOW || !isEditActive) {
    // return window.location.reload()
    return setJobNumContext(initialValues)
   }
    setSowNum(data.selectedSOW.name)
    setJobNumContext({...jobNumContext, ...data.selectedSOW})
  }, [])

  useEffect(() => {
    if(!data.selectedSOW || !isEditActive) {
      setActiveStep(0)
      const currentJNC = jobNumContext
      return setJobNumContext({...initialValues,
        clients: currentJNC.clients,
      })
    }
   }, [router.location])

  const steps = getSteps()

  const [activeStep, setActiveStep] = useState(0)
  const [jobString, setJobString] = useState("")

  const handleNext = () => {
    if (activeStep === getSteps().length - 1) {
      handleGen()
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClear = () => {
    const { clients, ...newVals } = initialValues
    setJobNumContext({ ...jobNumContext, ...newVals })
    setActiveStep(0)
    window.location.reload(false)
  }
  

  const handleGen = async() => {

    let sequence;
    if(!isEditActive) {
      sequence = await (await firestore.collection("sequencer").doc("sows").get()).data()
    } else if(jobNumContext.selectedMarket && isEditActive){
      sequence = {value: parseInt(jobNumContext.name.split(jobNumContext.selectedMarket)[1].split("-")[2])}
    } else if(!jobNumContext.selectedMarket && isEditActive) {
      sequence = {value: parseInt(jobNumContext.name.split("-")[2])} 
    }
  
    
    const capturedSOWString = `${jobNumContext.selectedClient}-${
      jobNumContext.selectedMarket ? jobNumContext.selectedMarket + "-" : ""
    }${jobNumContext.selectedFY}-${
      sequence.value
    }-${jobNumContext.selectedJobCode}-SOW`

    const { clients, ...rest } = jobNumContext
    
    if(isEditActive && sowNum !==capturedSOWString ) {
      firestore.collection('clients').doc(jobNumContext.selectedClient).collection('sows').doc(sowNum).delete()
      firestore.collection("allSOWs").doc(sowNum).delete()
    }
    setJobString(capturedSOWString)
    firestore
      .collection("clients")
      .doc(jobNumContext.selectedClient)
      .collection("sows")
      .doc(capturedSOWString)
      .set({
        ...rest,
        name: capturedSOWString,
        fiscalYear: jobNumContext.selectedFY,
        client: jobNumContext.selectedClient,
        market: jobNumContext.selectedMarket,
        dateCreated: isEditActive? jobNumContext.dateCreated : Date.now(),
        dateUpdated: Date.now(),
        clientID: jobNumContext.selectedClientID,
        description: jobNumContext.selectedJobCode,

      })
      .then(() => console.log("Document written to client"))
      .catch(error => console.log("Error writtind document", error))

    firestore
      .collection("allSOWs")
      .doc(capturedSOWString)
      .set({
        ...rest,
        name: capturedSOWString,
        fiscalYear: jobNumContext.selectedFY,
        client: jobNumContext.selectedClient,
        market: jobNumContext.selectedMarket,
        dateCreated: Date.now(),
        clientID: jobNumContext.selectedClientID,
        description: jobNumContext.selectedJobCode,
      })
      .then(() => console.log("Document written to allJobs"))
      .catch(error => console.log("Error writtind document", error))
    setJobNumContext(initialValues)
    if(!isEditActive) {
      firestore.collection("sequencer").doc("sows").set({value: sequence.value + 1})
    }
  

    if(isEditActive) {
      router.push('/current-sows')
    }
  }

  const requiredFields = [
    jobNumContext.selectedClient,
    jobNumContext.clients.find(
      client => client.id === jobNumContext.selectedClient
    )?.markets.length
      ? jobNumContext.selectedMarket
      : true,
    // jobNumContext.clientContactName && jobNumContext.clientContactName.length > 5,
    // jobNumContext.clientContactEmail && jobNumContext.clientContactEmail.toLowerCase()
    // .match(
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // ),
    jobNumContext.isNewClient.length,
    jobNumContext.accountSvcLeader,
    jobNumContext.selectedFY,
    jobNumContext.startDate &&
      jobNumContext.endDate &&
      jobNumContext.sowDueDate,
    jobNumContext.revenueByMonth.length > 0,
    jobNumContext.selectedJobCode.length > 0,
    jobNumContext.departmentsInvolved.length > 0,
    jobNumContext.confidenceInClosing,
    jobNumContext.sowStatus,
    jobNumContext.notes,
    !jobString
  ]

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <ClientSelect clients={jobNumContext.clients} isEditActive={isEditActive}/>
      case 1:
        return <MarketSelect clients={jobNumContext.clients} isEditActive={isEditActive}/>
      case 2:
        return <NewClient/>
      // case 2:
      //   return (
      //     <TextInput
      //       fieldName="clientContactName"
      //       validation={jobNumContext.clientContactName.length > 5}
      //       fieldDescription="Enter a Contact Name"
      //       validationText="Enter a valid Name"
      //     />
      //   )
      //   case 3:
      //     return (
      //       <TextInput
      //         fieldName="clientContactEmail"
      //         validation={jobNumContext.clientContactEmail.toLowerCase()
      //           .match(
      //             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //           )}
      //         fieldDescription="Enter a Contact Email"
      //         validationText="Enter a valid Email"
      //       />
      //     )
      case 3:
        return (
          <DropDown
            options={accountServiceLeaders}
            label="Account Service Leader"
            fieldName="accountSvcLeader"
          />
        )
      case 4:
        return <FYSelect isEditActive={isEditActive}/>
      case 5:
        return (
          <>
            <DateRangePicker fieldName="startDate" label="Project Start Date" />
            <DateRangePicker fieldName="endDate" label="Project End Date" />
            <DateRangePicker fieldName="sowDueDate" label="SOW Due Date" />
          </>
        )
      case 6:
        return <RevenueByMonth />
      case 7:
        return <JobDescription isEditActive={isEditActive}/>
      case 8:
        return <Departments />
      case 9:
        return (
          <DropDown
            options={confidence}
            label="Confidence In Closing"
            fieldName="confidenceInClosing"
          />
        )
      case 10:
        return (
          <DropDown options={status} label="SOW Status" fieldName="sowStatus" />
        )
      case 11:
        return (
          <TextInput
            fieldDescription="Enter Your Notes"
            fieldName="notes"
            validation={true}
            textArea={{
              multiline: true,
              minRows: 5,
              maxRows: 4
            }}
          />
        )
      case 12:
        return jobString && <Typography variant={"h2"}>{jobString}</Typography>
      default:
        return "Unknown step"
    }
  }

  return (
    <ActionNavigator
      activeStep={activeStep}
      steps={steps}
      getStepContent={getStepContent}
      handleBack={handleBack}
      handleClear={handleClear}
      requiredFields={requiredFields}
      handleNext={handleNext}
      isGenerated={jobString.length>0}
      isEditActive={isEditActive}
    />
  )
}

export default SOWDefiner
