
export const accountServiceLeaders = [
  "Andrea Dominguez",
  "Andrew Finley",
  "Corinne Farrell",
  "Crystal Monteleone",
  "Dori Albora",
  "Kathleen Perley",
  "Maria Rodriguez",
  "Marissa Guest",
  "Rachel Couture",
  "Sally Turner",
  "Sarah Bardwell",
  "Vanessa Ames",
  ]
  
export const confidence = [
    "0% SOW declined",
    "25% confidence - client showed interest",
    "40% confidence - client mentioned need",
    "75% confidence - client requested Decode submit proposal",
    "90% confidence - verbal approval",
    "100% confidence - signed scope"
  ]
  
export const status = [
    "In Development",
    "Internal Revisions",
    "Sent to Client",
    "Client Revisions",
    "Client verbal approval",
    "Approved",
    "Declined",
    "On Hold",
    "With CSH National"
  ]