export function timeConverter(UNIX_timestamp) {
  var a = new Date( UNIX_timestamp.seconds ? UNIX_timestamp.seconds*1000 : UNIX_timestamp)
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
  var year = a.getFullYear()
  var month = months[a.getMonth()]
  var date = a.getDate()
  var time = date + " " + month + " " + year
  return time
}
