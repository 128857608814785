import React, { useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import { useJobNumContext } from "../context/JobNumContext"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

// The hour and cost estimation fields used in the generation of a job number.
const Revenue = () => {
  const classes = useStyles()
  const {jobNumContext, setJobNumContext} = useJobNumContext();
  const [revenueError, setRevenueError] = useState(false)


  const onRevUpdate = ({ target }) => {
    if (target.value.match(/^[0-9]*$/)) {
      setRevenueError(false)
      setJobNumContext({
        ...jobNumContext,
        totalRevenue: parseInt(target.value)
      })
    } else {
      setRevenueError(true)
    }
  }

  return (
    <FormControl className={classes.formControl}>
    <TextField
        id="totalRevenue"
        label="Total Revenue"
        value={jobNumContext.totalRevenue}
        onChange={onRevUpdate}
        error={revenueError}
        helperText="Only enter numeric values"
    />
    </FormControl>
  )
}

export default Revenue
