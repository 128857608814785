import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import { useJobNumContext } from "../context/JobNumContext"
import Help from '@material-ui/icons/Help';
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '12px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth: '120px',
  }
}))

// builds out the options for fiscal year based on the device local time.
const NewClientDropDown = () => {

  return (
    [
      <MenuItem key={'no'} value={'no'}>No</MenuItem>,
      <MenuItem key={'yes'} value={'yes'}>Yes</MenuItem>
    ]
  )
}

// A year selector used in the generation of a job number.
const NewClient = ({isEditActive}) => {
  const classes = useStyles()
  const {setJobNumContext, jobNumContext} = useJobNumContext()

  const handleNewClientChange = ({ target }) => {
    setJobNumContext({
      ...jobNumContext,
      isNewClient: target.value
    })
  }

  const handleNewClientNameChange = ({ target }) => {
      setJobNumContext({
        ...jobNumContext,
        newClientName: target.value
      })
  }

  return (
    <>
    <FormControl className={classes.formControl}>
      <InputLabel>Is this a new client?</InputLabel>
      <Select
        className={classes.selectEmpty}
        onChange={handleNewClientChange}
        value={jobNumContext.isNewClient}
      >
        {NewClientDropDown()}
      </Select>
      <Tooltip title="Consider new all clients that have been working with DECODE for 1 year or less." >
        <Help color="primary" style={{cursor: 'pointer'}} />
      </Tooltip>
      
    </FormControl>
    {
      jobNumContext?.isNewClient === 'yes' &&
      <FormControl className={classes.formControl}>

        <TextField
              id="newClientName"
              label="New Client Name"
              value={jobNumContext.newClientName}
              onChange={handleNewClientNameChange}
              // error={jobCodeError}
            />
      </FormControl>
    }
    </>
  )
}

export default NewClient
