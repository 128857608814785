import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { useJobNumContext } from "../context/JobNumContext"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))



const containerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '450px'
}

// A year selector used in the generation of a job number.
const DropDown = ({options, label, fieldName, onChange, value, isMultiSelect, styles, dropdownStyles}) => {
  const classes = useStyles()
  const {setJobNumContext, jobNumContext} = useJobNumContext()


  const handleChange = ({ target }) => {
    if(onChange) {
        return onChange(target)
    }
    setJobNumContext({
        ...jobNumContext,
        [fieldName]: target.value
    })
  }




  return (
      <>
        <div style={containerStyles}>
            <FormControl className={classes.formControl} style={{...dropdownStyles}}>
                <InputLabel>{label}</InputLabel>
                {
                    !isMultiSelect ?
                    <Select
                        className={classes.selectEmpty}
                        onChange={handleChange}
                        value={value ? value : jobNumContext[fieldName]}
                    >
                        {
                            options.map(option => 
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            )
                        }
                    </Select> :
                         <Select
                         multiple
                         renderValue={(selected) => selected.join(", ")}
                         value={jobNumContext[fieldName]}
                         onChange={handleChange}
                         MenuProps={styles}
                       >
                           {options.map((option) => (
                           <MenuItem key={option} value={option}>
                             <ListItemIcon>
                               <Checkbox checked={jobNumContext[fieldName].indexOf(option) > -1} />
                             </ListItemIcon>
                             <ListItemText primary={option} />
                           </MenuItem>
                         ))}
                       </Select>

                }
            </FormControl>
        </div>
      </>
  )
}

export default DropDown
