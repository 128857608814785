import React, {useState} from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"

import { useJobNumContext } from "../context/JobNumContext"
import DropDown from './DropDown'
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import AddCircle from '@material-ui/icons/AddCircle';
import { IconButton } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';

const allDepartments = [
    "Account Services",
    "Content",
    "Creative",
    "Social",
    "Strategy",
    "MarTech",
    "Development",
    "Media/Analytics",
    "Project Management",
    "Production"
]
const containerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '450px'
}

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }))
  

// A year selector used in the generation of a job number.
const Departments = () => {
    const classes = useStyles()
    const {jobNumContext, setJobNumContext} = useJobNumContext()
    const [isEditMode, setIsEditMode] = useState(false);
    const [updateIndex, setUpdateIndex] = useState(-1);
    const [departmentPercentage, setDepartmentPercentage] = useState({department: allDepartments[0], percentage: 0, actualAmount: 0, memo: ''})
    const [actualAmountError, setActualAmountError] = useState(false)
    const handleDepartmentChange = (target) => {
        setDepartmentPercentage({...departmentPercentage, department: target.value})
    }
    const handlePercentageChange = ({target}) => {
        if (target.value.match(/^[1-9][0-9]?$|^100$/)) {
          setDepartmentPercentage({...departmentPercentage, percentage: target.value})
        }
      }
      const handleActualAmountChange = ({target}) => {
        if (target.value.match(/^(?!(0))[0-9]*$/)) {
            setActualAmountError(false)
            setDepartmentPercentage({...departmentPercentage, actualAmount: parseInt(target.value)})
          } else {
            setActualAmountError(false)
          }
      }
    const handleMemoChange = ({target}) => {
    setDepartmentPercentage({...departmentPercentage, memo: target.value})
    }

    const onAddDepartmentPercentage = () => {
        if(departmentPercentage.percentage > 0 || departmentPercentage.actualAmount > 0) {
            setJobNumContext({
                ...jobNumContext,
                departmentsInvolved: [...jobNumContext.departmentsInvolved, departmentPercentage]
            })
            setDepartmentPercentage({department: allDepartments[0], percentage: 0, actualAmount: 0, memo: ''})
        }
    }
    const onEditDepartmentPercentage = (i) => {
        setIsEditMode(true);
        setDepartmentPercentage(jobNumContext.departmentsInvolved[i]);
        setUpdateIndex(i)
      }
    const onUpdateRevenueByMonth = () => {
        jobNumContext.departmentsInvolved[updateIndex] = departmentPercentage
        setIsEditMode(false)
        setUpdateIndex(-1);
        setDepartmentPercentage({department: allDepartments[0], percentage: 0, actualAmount: 0, memo: ''})
    }
    const onDeleteDepartmentPercentage = (i) => {
        const newDeps = [...jobNumContext.departmentsInvolved];
        newDeps.splice(i,1);
        setJobNumContext({
            ...jobNumContext,
            departmentsInvolved: newDeps
        })
    }

    return (
        <>
             <ul>
            {
                jobNumContext.departmentsInvolved.length > 0 && jobNumContext.departmentsInvolved.map((i, index ) => {
                    return(
                        <li key={index}>
                            <span style={{minWidth: '275px', display: 'inline-block'}}><em>Department:</em><strong> {i.department}</strong></span>
                            <span style={{minWidth: '275px', display: 'inline-block'}}><em>Percentage:</em><strong> {i.percentage}</strong></span>
                            <span style={{minWidth: '275px', display: 'inline-block'}}><em>Amount:</em><strong> $ {i.actualAmount}</strong></span>
                            <span style={{minWidth: '275px', display: 'inline-block'}}><em>Memo:</em><strong> {i.memo}</strong></span>
                            <span>
                              <IconButton onClick={onEditDepartmentPercentage.bind(this, index)}>
                                <Edit color="primary" style={{cursor: 'pointer'}} />
                              </IconButton>
                            </span>
                            <span>
                              <IconButton onClick={onDeleteDepartmentPercentage.bind(this, index)}>
                                  <DeleteIcon color="error" style={{cursor: 'pointer'}} />
                              </IconButton>
                            </span>
                        </li>
                    )
                })
            }
            </ul>
            <div style={containerStyles}>
                <DropDown options={allDepartments} label="Department" onChange={handleDepartmentChange} value={departmentPercentage.department}/>
                <FormControl className={classes.formControl}>
                    <TextField
                    id="percentage"
                    label="Percentage"
                    value={departmentPercentage.percentage}
                    onChange={handlePercentageChange}
                    type="number"
                    // error={revenueError}
                    helperText="Enter a percentage between 1-100"
                    />
                </FormControl>
                <FormControl className={classes.formControl}>

                    <TextField
                    id="actualAmount"
                    label="Amount"
                    value={departmentPercentage.actualAmount}
                    onChange={handleActualAmountChange}
                    error={actualAmountError}
                    type="number"
                    helperText="Only enter numeric values"
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                <TextField
                id="memo"
                label="Memo"
                value={departmentPercentage.memo}
                onChange={handleMemoChange}
                // error={revenueError}
                // helperText="Only enter numeric values"
                />
                </FormControl>
                {
                    !isEditMode ?
                    <IconButton onClick={onAddDepartmentPercentage} disabled={departmentPercentage === 0}>
                        <AddCircle color="primary" style={{cursor: 'pointer'}} />
                    </IconButton> :
                    <IconButton onClick={onUpdateRevenueByMonth} disabled={departmentPercentage.actualAmount === 0 && departmentPercentage.percentage === 0}>
                        <Check color="primary" style={{cursor: 'pointer'}} />
                     </IconButton>


                }
            </div>
        </>
    )
}

export default Departments
