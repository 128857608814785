import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
}

firebase.initializeApp(firebaseConfig)

const provider = new firebase.auth.GoogleAuthProvider()

export const auth = firebase.auth()

export const signInWithGoogle = () => auth.signInWithPopup(provider)

export const signOut = () => auth.signOut()

export const firestore = firebase.firestore()

// The above is boilerplate firebase configuration from the JS documentation.