import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepContent from "@material-ui/core/StepContent"
import StepLabel from "@material-ui/core/StepLabel"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const ActionNavigator = ({activeStep, steps, getStepContent, handleBack, handleClear, requiredFields, handleNext, isGenerated, isEditActive}) => {

  const classes = useStyles()

    return(
        <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography component={'span'} variant={'body2'}>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <FormControl className={classes.formControl}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClear}
                      >
                        Reset
                      </Button>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Button
                        disabled={!(!!requiredFields[activeStep]) || (activeStep === steps.length - 1 && isGenerated)}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 && isEditActive ? "UPDATE" : activeStep === steps.length - 1 ? "GENERATE" : "Next"}
                      </Button>
                    </FormControl>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - Enter another job?</Typography>
            <Button onClick={handleClear} className={classes.button}>
              Start over
            </Button>
          </Paper>
        )}
      </div>
    )
}

export default ActionNavigator;