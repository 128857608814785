import React, { useEffect } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import { firestore } from "../firebase"
import { useHistory, useLocation } from "react-router-dom"
import { initialValues, useJobNumContext } from "../context/JobNumContext"
import TextInput from '../components/TextInput';
import { IconButton } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320
  }
}))

// This view is accessable at the uri /new-clients
// however as it isn't fully built out functionality it won't be found in nav
// the largest piece missing from this is the concept of access control.
// i.e. who can modify what data?
const NewClient = ({isEditActive}) => {
  const classes = useStyles()
  const {jobNumContext, setJobNumContext} = useJobNumContext()
  const router = useHistory();
  const [clientNo, setClientNo] = React.useState(0)
  const [market, setMarket] = React.useState({name: '', acronym: ''})
  const [clientContact, setClientContact] = React.useState({name: '', email: ''});
  const data = useLocation()

  useEffect(() => {
    if(!data.client && isEditActive) {
      return router.push('/current-clients')
    } else if (data.client && isEditActive) {
      return setJobNumContext({...jobNumContext, clientAdd: {...data.client}})
    }
   }, [])

   useEffect(() => {
    if(!data.selectedSOW && !isEditActive) {
      const currentJNC = jobNumContext
      return setJobNumContext({...initialValues,
        clients: currentJNC.clients,
        currClientJobs: currentJNC.currClientJobs
      })
    }
   }, [router.location])
 
  const addClientContact = () => {
    setJobNumContext({...jobNumContext, clientAdd: {...jobNumContext.clientAdd, contacts: [...jobNumContext.clientAdd.contacts, clientContact]}});
    setClientContact({name: '', email: ''});
  }

  const addMarket = () => {
    setJobNumContext({...jobNumContext, clientAdd: {...jobNumContext.clientAdd, markets: [...jobNumContext.clientAdd.markets, market]}});
    setMarket({name: '', acronym: ''});
  }

  const addClient = () => {
    firestore
      .collection("clients")
      .doc(jobNumContext.clientAdd.acronym)
      .set({...jobNumContext.clientAdd, clientNo: isEditActive ? jobNumContext.clientAdd.clientNo : clientNo})
      .then(() => console.log("Document written"))
      .catch(error => console.log("Error writtind document", error))
      router.push('/current-clients')
  }
  useEffect(() => {
    const unsub = firestore.collection("clients").onSnapshot(snapshot => {
      const currClientNum = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })).length
      setClientNo(isEditActive ? jobNumContext.clientAdd.clientNo : currClientNum + 1)
    })
    return () => unsub()
  }, [])

  const onDeleteContact = (i) => {
    setJobNumContext({...jobNumContext, clientAdd: {...jobNumContext.clientAdd, contacts: jobNumContext.clientAdd.contacts.filter((item,index) => index !== i)}});
  }
  const onDeleteMarket = (i) => {
    setJobNumContext({...jobNumContext, clientAdd: {...jobNumContext.clientAdd, markets: jobNumContext.clientAdd.markets.filter((item,index) => index !== i)}});
  }

  return (
    <div style={{ display: "grid", gridTemplateColumns: "34% 66%" }}>
      <div style={{ padding: "2rem" }}>
        <div style={{ padding: "1rem 0" }}>
          <TextInput
                fieldName="clientAdd"
                nestedFieldName="fullName"
                validation={jobNumContext.clientAdd.fullName?.length > 2}
                fieldDescription="Client Name"
                validationText="Enter the Client Name"
            />
            <TextInput
                fieldName="clientAdd"
                nestedFieldName="acronym"
                validation={jobNumContext.clientAdd.acronym?.length > 1}
                fieldDescription="Client Acronym"
                validationText="Enter the Client Acronym"
            />
        </div>
        <div style={{ padding: "1rem 0" }}>
          <FormControl className={classes.formControl}>

            <TextField
              id="new-client-contact-name"
              label="Client Contact Name"
              onChange={({ target }) => setClientContact({...clientContact,name: target.value})}
              value={clientContact.name}
            />
          </FormControl>
          <FormControl className={classes.formControl}>

            <TextField
              id="new-client-contact-email"
              label="Client Contact Email"
              onChange={({ target }) => setClientContact({...clientContact, email: target.value})}
              value={clientContact.email}
            />
          </FormControl>
          <div style={{ marginTop: "1rem"}}>
            <Button
              disabled={!(!!clientContact.name.length && !!clientContact.email.length)}
              onClick={addClientContact}
            >
              Add Client Contact
            </Button>
          </div>
          {
            <ul style={{width: '600px'}}>
            {
                jobNumContext?.clientAdd?.contacts && jobNumContext?.clientAdd?.contacts.length > 0 && jobNumContext.clientAdd.contacts.map((i, index ) => {
                    return(
                        <li key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '15px'}}>
                            <span style={{minWidth: '20%' }}><em>Name:</em><strong> {i.name}</strong></span>
                            <span style={{minWidth: '50%', display: 'inline-block'}}><em>Email:</em><strong> {i.email}</strong></span>
                            <span>
                                <IconButton onClick={onDeleteContact.bind(this, index)}>
                                    <DeleteIcon color="error" style={{cursor: 'pointer'}} />
                                </IconButton>
                            </span>
                        </li>
                    )
                })
            }
            </ul>
          }

        </div>
        <div style={{ padding: "1rem 0" }}>
        <FormControl className={classes.formControl}>
            <TextField
              id="market-name"
              label="Market Name"
              onChange={({ target }) => setMarket({...market, name:target.value})}
              value={market.name}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="market-acronym"
              label="Market Acronym"
              onChange={({ target }) => setMarket({...market, acronym: target.value})}
              value={market.acronym}
            />
          </FormControl>
          <div style={{ marginTop: "1rem"}}>
            <Button
              disabled={!(!!market.name && !!market.acronym)}
              onClick={addMarket}
            >
              Add Market
            </Button>
          </div>
          {
            <ul style={{width: '600px'}}>
            {
                jobNumContext?.clientAdd?.markets && jobNumContext?.clientAdd?.markets.length > 0 && jobNumContext.clientAdd.markets.map((i, index ) => {
                    return(
                        <li key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '15px'}}>
                            <span style={{minWidth: '45%' }}><em>Name:</em><strong> {i.name}</strong></span>
                            <span style={{minWidth: '45%', display: 'inline-block'}}><em>Acronym:</em><strong> {i.acronym}</strong></span>
                            <span>
                                <IconButton onClick={onDeleteMarket.bind(this, index)}>
                                    <DeleteIcon color="error" style={{cursor: 'pointer'}} />
                                </IconButton>
                            </span>
                        </li>
                    )
                })
            }
            </ul>
          }
        </div>
        <div style={{ paddingTop: "1rem", marginTop: "2rem" }}>
          <div>
              <Button
                disabled={!(!!jobNumContext.clientAdd.fullName && !!jobNumContext.clientAdd.acronym)}
                onClick={() => addClient()}
              >
                {
                  isEditActive ? 'Update Client' : 'Add Client'
                }
              </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewClient