import { createContext, useContext, useState } from 'react';



export const initialValues = {
  clients: [],
  isNewClient: 'no',
  clientContactName: "",
  clientContactEmail: "",
  selectedClient: "",
  accountSvcLeader: "",
  selectedClientID: 0,
  selectedMarket: "",
  selectedFY: "",
  selectedJobCode: [],
  selectedTimeEstimate: "",
  selectedTotalCost: "",
  currClientJobs: "",
  authUser: null,
  isRetainer: "no",
  retainerCode: [],
  departmentsCost: [],
  approvedBudget: 0,
  approvedHours: 0,
  totalRevenue: 0,
  revenueByMonth: [],
  departmentsInvolved: [],
  confidenceInClosing: "",
  sowStatus: "",
  notes: "",
  clientBillingContact: "",
  clientBillingContactEmail: "",
  clientBillingAddress: "",
  billingInstructions: "",
  billingInvoiceMemo: "",
  invoiceNumber: "",
  clientAdd:{
    contacts:[],
    fullName: '',
    acronym: '',
    markets: []
  }
};


export const JobNumContext = createContext(null);



export function JobNumProvider({ children }) {
  const [jobNumContext, setJobNumContext] = useState(initialValues);

  return (
    <>
      <JobNumContext.Provider value={{jobNumContext, setJobNumContext}}>{children}</JobNumContext.Provider>
    </>
  );
}
export function useJobNumContext() {
  return useContext(JobNumContext);
}
