export function padLeadingZeros(num) {
  let zeroCount = 0;
  for(let i=0; i<num.length; i++) {
    if(num[i]=== '0') {
      zeroCount = zeroCount + 1;
      continue;
    }
    break;
  }
  const zeros = num.substring(0,zeroCount);
  const number = parseInt(num.substring(zeroCount,num.length))+1
  return zeros + number
}
/**
 * 
 * @param {number=} number - Number to pad left
 * @returns Input number prepended with 0s
 */
const pad = number => {
  const num = parseInt(number).toString()
  if (num.length > 4) {
    return num.toString()
  } else {
    let output = "000" + num
    return output.substr(output.length - 4)
  }
}

export default pad
