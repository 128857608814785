import React from "react"
import { firestore } from "../firebase"


// The home view is a simple landing page// The functionality is a duplicate of the navigation drawer.
const Updates = () => {

  const deploy = async() => {
    const data = await (
        console.log('here'),
        await firestore.doc(`allJobs/CHD-23-100080-Account-PM`).get()
      ).data()
      await firestore.doc(`allJobs/CHD-AZ-23-100080-Account-PM`).set({
        ...data
      }, {merge: true})
      
    console.log(data)
  }
  return (
    <>
        <button onClick={deploy}>Run</button>
    </>
  )
}

export default Updates
