import React from "react"
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx';
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 18,
    marginLeft: 10
  }
}))

// A small button component that uses the FileSaver package to save a CSV
const DownloadCsv = (props) => {
  const { results, type } = props
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const classes = useStyles()

  const handleClick = (e) => {
    e.preventDefault()
    const convertDate = (d) => {
      let date =  new Date(d)
      let options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
      };
      return date.toLocaleDateString('en', options)
    }
    
    const newResult = results.map(item => {
      return type === 'sow' ?
       {
        Name: item.name,
        Client: item.client,
        Market: item?.market,
        ['Fiscal Year']: item.fiscalYear,
        Description: item.description[0],
        ['Date Created']: convertDate(item.dateCreated),
        Jobs: item?.jobs ? item.jobs.join(', ') : '',
        Attachments: item?.file ? item.file.downloadURL : ''
      }:
      {
        Name: item.name,
        Client: item.client,
        Market: item?.market,
        ['Fiscal Year']: item.fiscalYear,
        Description: item.description,
        ['Total Revenue']: item.totalRevenue,
        ['Date Created']: convertDate(item.dateCreated),
        SOW: item?.sow ? item.sow : '',
        ['Approved Hours']: item?.approvedHours,
        ['Approved Budget']: item?.approvedBudget
      }
  
    })

    const ws = XLSX.utils.json_to_sheet(newResult);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, `results${Date.now()}${fileExtension}`)
  }

  return (
    <Button 
      className={classes.button} 
      onClick={handleClick} 
      variant="contained"
      color="primary" 
    >
      Download Results
    </Button>
  )
}

export default DownloadCsv