import React, { useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import { useJobNumContext } from "../context/JobNumContext"
import RetainerSelect from "./RetainerSelect"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

// A text input field for inserting the job description.
const JobDescription = ({isEditActive}) => {
  const classes = useStyles()
  const {jobNumContext, setJobNumContext} = useJobNumContext();
  const [jobCodeError, setJobCodeError] = useState(false);

  // A simple validation check preventing the insertion of any non-alphanumeric characters
  const handleJobCodeChange = ({ target }) => {
    if (target.value.match(/^[a-z0-9]+$/i) && target.value.length < 16) {
      setJobNumContext({
        ...jobNumContext,
        selectedJobCode: [target.value]
      })
      setJobCodeError(false)
    } else {
      setJobCodeError(true)
    }
  }

  return (
    <>
      <FormControl className={classes.formControl}>
        <TextField
          id="JobCode"
          label="Description"
          value={jobNumContext.selectedJobCode}
          onChange={handleJobCodeChange}
          error={jobCodeError}
          helperText="Text and spaces up to 15 characters"
          // disabled={isEditActive}
        />
        <RetainerSelect />
      </FormControl>

    </>
  )
}

export default JobDescription
