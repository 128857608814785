import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { useJobNumContext } from "../context/JobNumContext"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

// builds out the options for fiscal year based on the device local time.
const generateFiscalYear = () => {
  const today = new Date()
  const year = today.getFullYear()
  const currentYear = parseInt(year.toString().substring(2))
  const lastYear = parseInt((year - 1).toString().substring(2))
  const nextYear = parseInt((year + 1).toString().substring(2))

  return (
    [
      <MenuItem key={lastYear} value={lastYear}>FY {lastYear}</MenuItem>,
      <MenuItem key={currentYear} value={currentYear}>FY {currentYear}</MenuItem>,
      <MenuItem key={nextYear} value={nextYear}>FY {nextYear}</MenuItem>
    ]
  )
}

// A year selector used in the generation of a job number.
const FYSelect = ({isEditActive}) => {
  const classes = useStyles()
  const {setJobNumContext, jobNumContext} = useJobNumContext()

  const handleFYChange = ({ target }) => {
    setJobNumContext({
      ...jobNumContext,
      selectedFY: target.value
    })
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Fiscal Year</InputLabel>
      <Select
        className={classes.selectEmpty}
        onChange={handleFYChange}
        value={jobNumContext.selectedFY}
        disabled={isEditActive}
      >
        {generateFiscalYear()}
      </Select>
    </FormControl>
  )
}

export default FYSelect
