import React from "react"
import { firestore } from "../firebase"
import { useCollection } from "react-firebase-hooks/firestore"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import { FormControl } from "@material-ui/core"
import { DataGrid } from "@mui/x-data-grid"
import DownloadCsv from "../components/DownloadCsv"
import {
  filterByClient,
  filterByMarket,
  filterByDate,
  convertToObjects
} from "../utils"
import { timeConverter } from "../utils/dateConverter"
import GridCellExpand from "../components/GridCellExpand"
import firebase from "firebase"
import { useHistory } from "react-router"

const useStyles = makeStyles(theme => ({
  jobResults: {
    flexGrow: 1,
    margin: 20
  },
  card: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    marginTop: 18
  }
}))

// A much more complicated datagrid view of all of the current jobs.
// you can filter down the paginated results and edit cells to update the data.
const CurrentJobs = () => {
  const classes = useStyles()
  const history = useHistory()
  const [clientFilter, setClientFilter] = React.useState("")
  const [marketFilter, setMarketFilter] = React.useState("")
  const [dateRangeLow, setDateRangeLow] = React.useState("")
  const [dateRangeHigh, setDateRangeHigh] = React.useState("")
  const [allJobsValue, AllJobsLoading, AllJobsError] = useCollection(
    firestore
      .collection("allJobs")
      .where("markedAsDeleted", "==", false),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )
  
  const [allClientsValue] = useCollection(firestore.collection("clients"), {
    snapshotListenOptions: { includeMetadataChanges: true }
  })

  const handleClientFilter = ({ target }) => {
    setMarketFilter("")
    setClientFilter(target.value)
  }

  const handleMarketFilter = ({ target }) => {
    setMarketFilter(target.value)
  }

  const handleDateRangeLow = ({ target }) => {
    setDateRangeLow(target.value)
  }

  const handleDateRangeHigh = ({ target }) => {
    setDateRangeHigh(target.value)
  }

  const handleClientClear = () => {
    setMarketFilter("")
    setClientFilter("")
    setDateRangeLow("")
    setDateRangeHigh("")
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300
    },
    {
      field: "description",
      headerName: "Description",
      width: 150
    },
    {
      field: "sow",
      headerName: "SOW",
      width: 300
    },
    {
      field: "totalRevenue",
      headerName: "Revenue",
      width: 150,
      renderCell: params => (params.value ? `$${params.value}` : "")
    },
    {
      field: "departmentsCost",
      headerName: "Department Costs",
      width: 150,
      renderCell: params => {
        const val = params?.value
          ? params.value.map(i => `${i.department}_$${i.cost}`).join(", ")
          : ""
        return (
          <GridCellExpand value={val} width={params.colDef.computedWidth} />
        )
      }
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      renderCell: params => (params.value ? timeConverter(params.value) : "")
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      renderCell: params => (params.value ? timeConverter(params.value) : "")
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 150,
      renderCell: params => timeConverter(params.value)
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 250,
      renderCell: params => {
        const onDeleteJob = async e => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter(c => c.field !== "__check__" && !!c)
            .forEach(
              c => (thisRow[c.field] = params.getValue(params.id, c.field))
            )
          if (window.confirm(`Do you really want to job ${thisRow.name}?`)) {
            const clientName = thisRow.name.split("-")[0]

            const currentSOW = await (
              await firestore.doc(`allSOWs/${thisRow.sow}`).get()
            ).data()

            const relatedJobs = currentSOW.jobs ? currentSOW?.jobs.filter(
              job => job !== thisRow.name
            ) : []

            await firestore
              .doc(`clients/${clientName}/sows/${thisRow.sow}`)
              .set({ jobs: relatedJobs }, { merge: true })
            await firebase
              .firestore()
              .doc(`allSOWs/${thisRow.sow}`)
              .set({ jobs: relatedJobs }, { merge: true })

             await firebase
              .firestore()
              .doc(`allJobs/${thisRow.name}`)
              .set({ markedAsDeleted: true }, { merge: true })
            await firebase
              .firestore()
              .doc(`clients/${clientName}/jobs/${thisRow.sow}`)
              .set({ markedAsDeleted: true }, { merge: true })
            window.alert(`Job ${thisRow.name} deleted!`)

            return history.push({
              pathname: "/current-sows",
            })

          }
        }

        const onEditJob = async e => {
          e.stopPropagation() // don't select this row after clicking

          const api = params.api
          const thisRow = {}

          api
            .getAllColumns()
            .filter(c => c.field !== "__check__" && !!c)
            .forEach(
              c => (thisRow[c.field] = params.getValue(params.id, c.field))
            )

          const selectedJob = await (
            await firebase
              .firestore()
              .doc(`allJobs/${thisRow.name}`)
              .get()
          ).data()

          return history.push({ pathname: "/edit-job", selectedJob })
        }

        return (
          <>
            <Button onClick={onEditJob} color="primary">
              Edit Job
            </Button>
            &nbsp;
            <Button onClick={onDeleteJob} color="secondary">
              Delete Job
            </Button>
          </>
        )
      }
    }
  ]

  let results
  if (allJobsValue) {
    const objects = convertToObjects(allJobsValue.docs)
    const clientFiltered = filterByClient(objects, clientFilter)
    const marketsFiltered = filterByMarket(clientFiltered, marketFilter)
    results = filterByDate(marketsFiltered, dateRangeLow, dateRangeHigh).map(
      (r, index) => ({ ...r, id: index })
    )
  }
  const [pageSize, setPageSize] = React.useState(10)

  return (
    <>
      {AllJobsError && (
        <strong>AllJobsError: {JSON.stringify(AllJobsError)}</strong>
      )}
      {AllJobsLoading && <span>Collection: AllJobsLoading...</span>}
      {allJobsValue && (
        <div className={classes.jobResults}>
          <Grid container spacing={3}>
            {allClientsValue && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="client-filter-dropdown">
                        Client
                      </InputLabel>
                      <Select
                        labelId="client-filter-dropdown"
                        value={clientFilter}
                        onChange={handleClientFilter}
                      >
                        {allClientsValue.docs.map((doc, index) => (
                          <MenuItem
                            key={"client" + index}
                            value={doc.data().acronym}
                          >
                            {doc.data().fullName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!!allClientsValue.docs
                      .filter(doc => doc.data().acronym === clientFilter)
                      .flatMap(doc => doc.data().markets).length && (
                      <FormControl className={classes.formControl}>
                        <InputLabel id="market-filter-dropdown">
                          Market
                        </InputLabel>
                        <Select
                          labelId="client-filter-dropdown"
                          value={marketFilter}
                          onChange={handleMarketFilter}
                        >
                          {allClientsValue.docs
                            .filter(doc => doc.data().acronym === clientFilter)
                            .flatMap(doc =>
                              doc.data().markets.map(market => (
                                <MenuItem
                                  key={market.acronym}
                                  value={market.acronym}
                                >
                                  {market.name}
                                </MenuItem>
                              ))
                            )}
                        </Select>
                      </FormControl>
                    )}
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="date"
                        label="Date Low"
                        type="date"
                        // defaultValue=""
                        value={dateRangeLow}
                        className={classes.textField}
                        onChange={handleDateRangeLow}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="date"
                        label="Date High"
                        type="date"
                        // defaultValue=""
                        value={dateRangeHigh}
                        className={classes.textField}
                        onChange={handleDateRangeHigh}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormControl>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={handleClientClear}
                    >
                      Clear
                    </Button>
                    <DownloadCsv results={results} type="job" />
                  </CardContent>
                </Card>
              </Grid>
            )}
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                rows={results.sort((a, b) => b.dateCreated - a.dateCreated)}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={size => setPageSize(size)}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                onCellEditCommit={(id, event) => console.log(id, event)}
              />
            </div>
          </Grid>
        </div>
      )}
    </>
  )
}

export default CurrentJobs
