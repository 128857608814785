import React, { useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router"
import Typography from "@material-ui/core/Typography"
import FYSelect from "./FYSelect"
import DateRangePicker from "./DatePicker"
import JobDescription from "./JobDescription"
import TextInput from "./TextInput"
import { firestore } from "../firebase"
import { useJobNumContext, initialValues } from "../context/JobNumContext"
import ActionNavigator from "./ActionNavigator"
import Revenue from "./Revenue"
import Departments from "./Departments"
import RevenueByMonth from "./RevenueByMonth"
import DropDown from "./DropDown"
import { accountServiceLeaders } from "../lib/data"
import ClientSelect from "./ClientSelect"
import MarketSelect from "./MarketSelect"
import NewClient from "./NewClient"
import { useCollection } from "react-firebase-hooks/firestore"

const getSteps = () => {
  return [
    "Select Client",
    "Select Market",
    "Is this a new client",
    "Account Service Leader",
    "Select Fiscal Year",
    "Job Date Estimates",
    "Expected Revenue By Month",
    "Enter Job Description Or Select a Retainer Type",
    "Departments Involved",
    "Total Estimate Revenue",
    // "Confidence In Closing",
    // "Billing Details",
    "Notes & Comments",
    "Finish"
  ]
}

const JobDefiner = ({ isEditActive }) => {

  console.log("testing account leaders", {...accountServiceLeaders})

  const location = useLocation()
  const history = useHistory()
  const { jobNumContext, setJobNumContext } = useJobNumContext()
  const [fetchedClients] = useCollection(firestore.collection("clients"), {
    snapshotListenOptions: { includeMetadataChanges: true }
  })

  //this useEffect hook updates the jobNumContext state with the fetched client data when the fetchedClients variable changes
  useEffect(() => {
    fetchedClients &&
      setJobNumContext({
        ...jobNumContext,
        clients: fetchedClients.docs.map(d => ({ ...d.data(), id: d.id }))
      })
  }, [fetchedClients])

  //this useEffect hook handles different scenarios based on the isEditActive flag and the location data. It updates the jobNumContext state with relevant data depending on the mode (edit or non-edit) and the presence of sow data
  useEffect(() => {
    if (isEditActive) {
      const { selectedJob } = location
      setJobNumContext({
        ...jobNumContext,
        ...selectedJob,
        selectedClient: selectedJob?.client,
        selectedClientID: selectedJob?.clientID,
        selectedMarket: selectedJob?.market,
        selectedFY: selectedJob?.fiscalYear,
        selectedJobCode: Array.isArray(selectedJob?.description) 
                   ? selectedJob?.description 
                   : [selectedJob?.description]
      })
    } else if (!location?.sow) {
      return history.push("/current-sows")
    } else {
      const { sow } = location
      setJobNumContext({
        ...jobNumContext,
        ...sow,
        selectedClient: sow.client,
        selectedClientID: sow.clientID,
        selectedMarket: sow.market,
        selectedFY: sow.fiscalYear,
        selectedJobCode: sow.description
      })
    }
  }, [])

  const steps = getSteps()
  const [activeStep, setActiveStep] = useState(0)
  const [jobString, setJobString] = useState([])

  const handleNext = () => {
    if (activeStep === getSteps().length - 1) {
      handleGen()
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep === 2) {
      jobNumContext.selectedJobCode = []
      jobNumContext.retainerCode = []
      jobNumContext.startDate = undefined
      jobNumContext.endDate = undefined
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleClear = () => {
    setJobNumContext(initialValues)
    setActiveStep(0)
    window.location.reload()
  }

  const fetchSequence = async () => {
    const sequence = await (
      await firestore.collection("sequencer").doc("jobs").get()
    ).data()
    return sequence
  }

  //This function generates the job number string based on the provided job code and sequence number
  const generateCapturedJobStr = (jc, sequence) => {
    return `${jobNumContext.client}-${
      jobNumContext?.market ? jobNumContext.market + "-" : ""
    }${jobNumContext.fiscalYear}-${sequence.value}-${jc}`
  }

  //his function creates and stores the job document in the appropriate collections within Firestore based on the provided parameters and job data
  const createJobDocument = async (
    capturedJobStr,
    rest,
    isRetainer,
    sequence
  ) => {

    const jobData = {
      ...rest,
      name: capturedJobStr,
      sow: jobNumContext.name,
      dateCreated: Date.now(),
      jobIndex: sequence.value,
      markedAsDeleted: false,
    }

    const jobCollectionRef = firestore
      .collection("clients")
      .doc(jobNumContext.client)
      .collection("jobs")

    if (isRetainer === "yes") {
      const retainerCollectionRef = jobCollectionRef
        .doc("retainer" + sequence.value)
        .collection("retainerJobs")
      await retainerCollectionRef.doc(capturedJobStr).set(jobData)
    } else {
      await jobCollectionRef.doc(capturedJobStr).set(jobData)
    }
    await firestore.collection("allJobs").doc(capturedJobStr).set(jobData)
  }

  //The following function updates the sequencer document in Firestore to ensure that the next job number generated is unique only if the edit mode is not active
  const updateSequencer = async sequence => {
    if (!isEditActive) {
      await firestore
        .collection("sequencer")
        .doc("jobs")
        .set({ value: sequence.value + 1 })
    }
  }

  const updateJOBDocuments = async capturedJob => {

    const jobCollectionRef = firestore
    .collection("clients")
    .doc(jobNumContext.client)
    .collection("jobs")

    const { clients, newClientName, ...rest } = jobNumContext
    const job = {
      ...rest,
      dateUpdated: Date.now(),
      name: [...jobNumContext.name.split("-").slice(0, -1), jobNumContext.selectedJobCode[0]].join("-")
    }

  
    await firestore.doc(`allJobs/${jobNumContext.name}`).delete()

    await jobCollectionRef.doc(jobNumContext.name).delete();

    await firestore.doc(`allJobs/${job.name}`).set(job)
    await jobCollectionRef.doc(job.name).set(job)
    


    const currentSow = await(
      await firestore
        .doc(`allSOWs/${jobNumContext.sow}`)
        .get()
    ).data()

    


    const jobsToUpdate = (currentSow?.jobs
      ? [...currentSow.jobs, job.name]
      : [job.name]).filter(job => job !== jobNumContext.name)

    const sowCleintCollectionRef = firestore.doc(
      `clients/${jobNumContext.client}/sows/${currentSow.name}`
    )

    const sowCollectionRef = firestore.doc(
      `allSOWs/${currentSow.name}`
    )
    await sowCleintCollectionRef.set({ jobs: jobsToUpdate }, { merge: true })
    await sowCollectionRef.set({ jobs: jobsToUpdate }, { merge: true })


   
    setJobNumContext(initialValues)
    setJobString([capturedJob])

  }

  const handleGen = async () => {
    let sequence

    if (!isEditActive) {
      sequence = await fetchSequence()
    } else if (jobNumContext.selectedMarket && isEditActive) {
      sequence = {
        value: parseInt(
          jobNumContext.name
            .split(jobNumContext.selectedMarket)[1]
            .split("-")[2]
        )
      }

    } else if (!jobNumContext.selectedMarket && isEditActive) {
      sequence = { 
        value: parseInt(
          jobNumContext.name
          .split("-")[2]
          ) 
        }
    }
    console.log("CONTEXT", jobNumContext)
    const capturedJobArrPromise = jobNumContext.selectedJobCode.map(
      async jc => {
        const capturedJobStr = generateCapturedJobStr(jc, sequence)
        const { clients, newClientName, ...rest } = jobNumContext
        if (!isEditActive) {
          await createJobDocument(
            capturedJobStr,
            rest,
            jobNumContext.isRetainer,
            sequence
          )
          await updateSequencer(sequence)
        }
        if(isEditActive) {
          await updateJOBDocuments(capturedJobStr)
        }
        return capturedJobStr
      }
    )

    
    const capturedJobArr = await Promise.all(capturedJobArrPromise)
    if(!isEditActive) {

      const currentSow = await(
        await firestore
          .doc(`allSOWs/${jobNumContext.name}`)
          .get()
      ).data()

      const jobsToUpdate = currentSow?.jobs
        ? [...currentSow.jobs, ...capturedJobArr]
        : capturedJobArr
      await firestore
        .doc(`allSOWs/${jobNumContext.name}`)
        .set({ jobs: jobsToUpdate }, { merge: true })
    }

    setJobNumContext(initialValues)
    setJobString(capturedJobArr)
  }

  const requiredFields = [
    jobNumContext?.selectedClient,
    jobNumContext?.clients.find(
      client => client.id === jobNumContext?.selectedClient
    )?.markets?.length
      ? jobNumContext?.selectedMarket
      : true,
    jobNumContext?.isNewClient?.length,
    !!jobNumContext?.accountSvcLeader,
    !!jobNumContext?.selectedFY,
    !!jobNumContext?.startDate &&
      !!jobNumContext?.endDate &&
      !!jobNumContext?.sowDueDate,
      jobNumContext?.revenueByMonth?.length > 0,
      jobNumContext?.selectedJobCode?.length > 0,
    jobNumContext?.departmentsInvolved?.length > 0,
    jobNumContext?.totalRevenue > 0,
    // !!jobNumContext.confidenceInClosing,
    // jobNumContext.clientBillingContact?.length > 5 &&
    // jobNumContext.clientBillingContactEmail.toLowerCase()
    // .match(
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // ) &&
    // jobNumContext.clientBillingAddress?.length > 5,
    !!jobNumContext.notes || true,
    true
  ]

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <ClientSelect
            clients={jobNumContext.clients}
            isEditActive={isEditActive}
          />
        )
      case 1:
        return (
          <MarketSelect
            clients={jobNumContext.clients}
            isEditActive={isEditActive}
          />
        )
      case 2:
        return <NewClient isEditActive={isEditActive} />
      case 3:
        return (
          <DropDown
            options={accountServiceLeaders}
            label="Account Service Leader"
            fieldName="accountSvcLeader"
          />
        )
      case 4:
        return <FYSelect isEditActive={isEditActive} />
      case 5:
        return (
          <>
            <DateRangePicker fieldName="startDate" label="Project Start Date" />
            <DateRangePicker fieldName="endDate" label="Project End Date" />
            <DateRangePicker fieldName="sowDueDate" label="SOW Due Date" />
          </>
        )
      case 6:
        return <RevenueByMonth />
      case 7:
        return <JobDescription isEditActive={isEditActive} />
      case 8:
        return <Departments />
      case 9:
        return <Revenue />
      // case 7:
      //   return (
      //     <DropDown
      //     options={confidence}
      //     label="Confidence In Closing"
      //     fieldName="confidenceInClosing"
      //   />
      //   )
      // case 8:
      //   return (
      //     <BillingDetails />
      //   )
      case 10:
        return (
          <TextInput
            fieldDescription="Enter Your Notes"
            fieldName="notes"
            validation={true}
            textArea={{
              multiline: true,
              minRows: 5,
              maxRows: 4
            }}
          />
        )
      case 11:
        return jobString?.map(job => (
          <Typography key={job} variant={"h2"}>
            {job}
          </Typography>
        ))
      default:
        return "Unknown step"
    }
  }

  return (
    <>
      <div style={{ margin: "20px" }}>
        <h1>{jobNumContext.selectedClientFullName}</h1>
        {jobNumContext.selectedMarketName && (
          <h3>{jobNumContext.selectedMarketName}</h3>
        )}
      </div>
      <ActionNavigator
        isGenerated={jobString.length > 0}
        activeStep={activeStep}
        steps={steps}
        getStepContent={getStepContent}
        handleBack={handleBack}
        handleClear={handleClear}
        requiredFields={requiredFields}
        handleNext={handleNext}
      />
    </>
  )
}

export default JobDefiner
