import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}))

// The home view is a simple landing page// The functionality is a duplicate of the navigation drawer.
const Home = () => {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h1">Home</Typography>
      <Typography className={classes.root}>
        <Link to="/new-sow">New SOW</Link>
      </Typography>
      <Typography className={classes.root}>
        <Link to="/current-sows">Current SOWs</Link>
      </Typography>
      {/* <Typography className={classes.root}>
        <Link to="/new-clients">New Client</Link>
      </Typography> */}
      <Typography className={classes.root}>
        <Link to="/current-jobs">Current Job</Link>
      </Typography>
      <Typography className={classes.root}>
        <Link to="/current-clients">Current Client</Link>
      </Typography>
    </>
  )
}

export default Home
