import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { useJobNumContext } from "../context/JobNumContext"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

const ClientSelect = ({ clients, isEditActive }) => {
  const classes = useStyles()
  const { setJobNumContext, jobNumContext } = useJobNumContext()

  const GenClientsMenuItems = clients.map(client => (
    <MenuItem key={client.clientNo + client.fullName} value={client.id}>
      {client.fullName}
    </MenuItem>
  ))


  const handleClientChange = ({ target }) => {
    setJobNumContext({
      ...jobNumContext,
      selectedClient: target.value,
      client: target.value,
      selectedClientID: clients.find(client => client.id === target.value)
        .clientNo,
      selectedClientFullName: clients.find(client => client.id === target.value)
        .fullName
    })
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Client</InputLabel>
      <Select
        className={classes.selectEmpty}
        onChange={handleClientChange}
        value={jobNumContext.selectedClient}
        disabled={isEditActive}
      >
        {GenClientsMenuItems}
      </Select>
    </FormControl>
  )
}

export default ClientSelect
