import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { useJobNumContext } from "../context/JobNumContext"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

// A form to select the market section of the job number.
const MarketSelect = ({clients, isEditActive}) => {
  const classes = useStyles()
  const {jobNumContext, setJobNumContext} = useJobNumContext()

  const handleMarketChange = (e, name, acronym) => {
    setJobNumContext({
      ...jobNumContext,
      selectedMarket: acronym,
      selectedMarketName: name
    })
  }

  // builds out select options based on the client information.
  const GenMarketsMenuItems = clients
    .find(client => client.id === jobNumContext.selectedClient)
    ?.markets
      .sort((a,b) => a.name.localeCompare(b.name))
      .map((market, index) => (
      <MenuItem key={"market" + index} onClick={(e)=>handleMarketChange(e, market.name, market.acronym)} value={market.acronym}>
        {market.name}
      </MenuItem>
    ))

  return clients.find(client => client.id === jobNumContext.selectedClient)?.markets
    .length ? (
    <FormControl className={classes.formControl}>
      <InputLabel>Market</InputLabel>
      <Select
        className={classes.selectEmpty}
        value={jobNumContext.selectedMarket}
        disabled={isEditActive}
      >
        {GenMarketsMenuItems}
      </Select>
    </FormControl>
  ) : (
    "This Client is not configured with markets, Skip for now."
  )
}

export default MarketSelect
