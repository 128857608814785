import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormLabel from "@material-ui/core/FormLabel"
import { useJobNumContext } from "../context/JobNumContext"
import DatePicker from './DatePicker';
import DropDown from "./DropDown"


const useStyles = makeStyles(theme => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 320
  }
}))

const options = [
  "SocialMedia",
  "Content",
  "WebMaint",
  "PaidMedia",
  "WebHost",
  "Database",
  "Email",
  "SEO",
  "WebDev",
  "AllAgency",
  "CreativeDesign",
  "RepManag",
  "Account-PM",
  "Strategy",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '320px'
    }
  },
  width: '320px',
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

// A form field to select the retainer value of a job number.
const RetainerSelect = props => {
  const {jobNumContext, setJobNumContext} = useJobNumContext();
  const classes = useStyles()


  // const handleRetainerCheckbox = ({ target }) => {
  //     setJobNumContext({
  //       ...jobNumContext,
  //       isRetainer: target.value
  //     })
  //     if(target.value === 'no') {
  //       setJobNumContext({
  //         ...jobNumContext,
  //         isRetainer: target.value,
  //         startDate: null,
  //         endDate: null,
  //         retainerCode: [],
  //         selectedJobCode: null
  //       })
  //     }
  // }

  const handelRetainerSelect = ( target) => {
    setJobNumContext({
      ...jobNumContext,
      retainerCode: target.value,
      selectedJobCode: target.value,
      description: target.value
    })
  }

  return (
    <>
      <FormControl className={classes.formControl} style={{minWidth: 320}}>
        {/* <FormLabel component="legend">Is this job on retainer</FormLabel>
        <RadioGroup 
          aria-label="retainer" 
          name="retainer" 
          value={jobNumContext.isRetainer} 
          onChange={handleRetainerCheckbox}
        >
          <FormControlLabel
            value="no"
            control={
              <Radio />
            }
            label="No"
          />
          <FormControlLabel
            value="yes"
            control={
              <Radio />
            }
            label="Yes"
          />
        </RadioGroup> */}
        
        {/* {jobNumContext.isRetainer === 'yes' && (
          <> */}
            <DropDown fieldName="retainerCode" label="Retainer Code" onChange={handelRetainerSelect} styles={MenuProps} isMultiSelect options={options} dropdownStyles={{minWidth: '450px', margin: 0}}/>
            {/* <DatePicker
              fieldName="startDate"
              label="Project Start Date"
            />
            <DatePicker
              fieldName="endDate"
              label="Project End Date"
            /> */}
          {/* </> */}
        {/* )} */}
      </FormControl>
    </>
  )
}

export default RetainerSelect
