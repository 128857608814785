import React from "react";
import { DatePicker } from "@material-ui/pickers";
import { useJobNumContext } from "../context/JobNumContext";


const DateRangePicker = ({fieldName, label}) => {
  const {jobNumContext, setJobNumContext} = useJobNumContext();

  return (

      <DatePicker
        autoOk
        variant="inline"
        label={label}
        value={jobNumContext[fieldName]?.seconds ? new Date(jobNumContext[fieldName].seconds * 1000) : jobNumContext[fieldName]}
        format="MM/dd/yyyy"
        onChange={(date)=> setJobNumContext({...jobNumContext, [fieldName]: new Date(date)})}
        style={{
            margin: '1rem 0 1rem',
            padding: '0 0 0 .5rem'
        }}
      />
  );
}

export default DateRangePicker;