import React, { useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import { useJobNumContext } from "../context/JobNumContext"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320
  }
}))

// A text input field for inserting the job description.
const TextInput = ({validation,fieldName, fieldDescription, validationText, disabled, textArea, nestedFieldName}) => {
  const classes = useStyles()
  const {jobNumContext, setJobNumContext} = useJobNumContext();
  const[isValid, setIsValid] = useState(true)

  // A simple validation check preventing the insertion of any non-alphanumeric characters
  const handleChange = ({ target }) => {
    setIsValid(target.value > 0)
    if(nestedFieldName) {
      return setJobNumContext({
      ...jobNumContext,
      [fieldName]: {
        ...jobNumContext[fieldName],
        [nestedFieldName]:  target.value
      }
      })
    }
    setJobNumContext({
      ...jobNumContext,
      [fieldName]:target.value
      })
  }


  return (
    <FormControl className={classes.formControl}>
      <TextField
        id={fieldName}
        label={fieldDescription}
        value={jobNumContext[fieldName][nestedFieldName]}
        onChange={handleChange}
        error={!validation && !isValid }
        helperText={validationText}
        disable={disabled}
        {
            ...textArea
        }
      />
    </FormControl>
  )
}

export default TextInput
