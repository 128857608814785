import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { firestore } from "../firebase"
import { useCollection } from "react-firebase-hooks/firestore"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"


const useStyles = makeStyles(theme => ({
  jobResults: {
    flexGrow: 1,
    margin: 20
  },
  card: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

// A simple view of cards to show all current clients.
// It isn't very informative, and functionaly it odesnt really hold value
// Id would be nice to flesh this out with editable fields and a updat function.
const CurrentClients = () => {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [value, loading, error] = useCollection(
    firestore.collection("clients"),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )


  const handleClickOpen = index => {
    setSelectedIndex(index)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedIndex(null)
  }

  const handleEdit = (client) => {
    return history.push({ pathname: "/edit-client", client })
    
  }

  const handleDelete = (client) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm(`Are you sure you want to delete ${client.fullName}.`) === true) {
      return firestore.collection('clients').doc(client.acronym).delete()
    }
  }

  return (
    <>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span>Collection: Loading...</span>}
      {value && (
        <div className={classes.results}>
          <Grid 
            container 
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {value.docs.map((doc, index) => (
              <Grid 
                item 
                xs={12} 
                md={3} 
                key={doc.id}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography>{doc.data().fullName}</Typography>
                  </CardContent>
                  <Button
                    onClick={() => handleClickOpen(index)}
                    color="primary"
                  >
                    details
                  </Button>
                  <Button
                    onClick={() => handleEdit(doc.data())}
                    color="default"
                  >
                    edit
                  </Button>
                  <Button
                    onClick={() => handleDelete(doc.data())}
                    color="secondary"
                  >
                    delete
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            {selectedIndex != null && (
              <>
                <DialogTitle id="form-dialog-title">
                  {value.docs[selectedIndex].data().fullName}
                </DialogTitle>
                <DialogContent>
                  {!!value.docs[selectedIndex].data().contacts.length && (
                    <Typography>Contacts:</Typography>
                  )}
                  <DialogContentText>
                    {!!value.docs[selectedIndex].data().contacts.length > 0
                      ? value.docs[selectedIndex]
                          .data()
                          .contacts.map(contact => (
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Typography style={{ padding: "12px 0" }}>Name: {contact.name} | Email: {contact.email}</Typography>
                            </Grid>
                          ))
                      : null}
                  </DialogContentText>

                  {!!value.docs[selectedIndex].data().markets.length && (
                    <Typography>Markets:</Typography>
                  )}
                  
                  <DialogContentText>
                    {!!value.docs[selectedIndex].data().markets.length
                      ? value.docs[selectedIndex]
                          .data()
                          .markets.map(market => (
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Typography style={{ padding: "12px 0" }}>{market.name}</Typography>

                            </Grid>
                          ))
                      : null}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </div>
      )}
    </>
  )
}

export default CurrentClients