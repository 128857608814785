/**
 * 
 * @param {array=} jobs - An array of job Objects
 * @param {string=} clientFilter - The current client name to filter by (can be null)
 * @returns Jobs Array filtered by client if clientName is supplied, otherwise returns the initial jobs array
 */

const filterByClient = (jobs, clientFilter) =>   
    clientFilter 
      ? jobs.filter(job => job.client === clientFilter) 
      : jobs


export default filterByClient
